app-wound-imaging {
    .imaging-container {
        --image-icon-size: 3.75rem;
        --image-icon-padding: 1.25rem;
        --image-icon-margin: 1.25rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .image-icon-background {
            padding: var(--image-icon-padding);
            background-color: var(--color-primary-light);
            border-radius: 50%;
            margin-bottom: var(--image-icon-margin);

            hrs-icon {
                --icon-color: var(--white);
                --icon-size: var(--image-icon-size);
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        @media (min-width: 600px) {
            --image-icon-size: 5rem;
            --image-icon-padding: 1.875rem;
            --image-icon-margin: 1rem;
        }
    }
}
