app-consent-form {
  --app-consent-form-padding: 1rem;

  hrs-content {
    hrs-text {
      --display: block;
      padding-bottom: calc(.5 * var(--app-consent-form-padding));
    }
  }

  @media (min-width: 600px) {
    --app-consent-form-padding: 2rem;    
  }
}
