app-home {
    ion-content {
        --ion-background-color: var(--gradient-background);
    }

    hrs-content {
        --height: fit-content;
        --overflow-y: initial;
        --content-bg: none;
    }
}
