@import '../../../theme/variables';

.c_edu_video {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    // center video element
    video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-color: black;
    }

    .c_edu_video--overlay {
        display: flex;
        position: absolute;
        z-index: 300;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: ease-in-out .4s;

        &.is-hidden {
            bottom: -4.5rem;
        }

        .c_edu_video--ctrl_group {
            position: absolute;
            bottom: 0;
            text-align: center;
            vertical-align: center;
            height: 3.75rem;
            width: 100%;
            display: flex;
            background-color: rgba(255, 255, 255, .9);
            align-items: center;
            justify-content: center;

            hrs-button {
                --color: var(--black);
            }

            @media (min-width: 600px) {
                height: 4.5rem;
            }
        }

        .c_edu_video--ctrl-backward, .c_edu_video--ctrl-forward {
            --btn-icon-size: 1.25rem;
            --width: unset;
            margin: 0;
            @media (min-width: 600px) {
                --btn-icon-size: 2rem;
            }
        }

        .c_edu_video--ctrl-play {
            margin: 1.625rem;
            --btn-icon-size: 1.75rem;
            --width: unset;
            @media (min-width: 600px) {
                margin: 1.6875rem;
                --btn-icon-size: 2.5rem;
            }
        }
    }
}

.header-transition {
    transition: transform .4s ease-in-out;
}

.hide-header {
    transform: translateY(calc(0vh - var(--header-height)));
}
