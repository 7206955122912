/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./app/app.component";
@import "./app/home/home.page";
@import "./app/wound-imaging/wound-imaging.page";
@import "./app/caregiver-pin/caregiver-pin.page";
@import "./app/consent-form/consent-form.page";
@import "./app/education/videos/education-videos.page";

@import "../../../libs/pattern-library/common-styles";
@import url(../../../libs/pattern-library/custom-properties.scss);

:root {
    @include hrs-custom-properties;
}

@media (orientation: landscape) {
    .l_modal-multistep {
        .modal-wrapper {
            --min-width: 100vw;
            --width: 100vw;
            --min-height: 100vh;
            --height: 100vh;
            border-radius: 0;
        }
    }
}

@media (orientation: portrait)
and (min-width: 500px) {

    .l_modal-multistep {
        .modal-wrapper {
            --min-width: 100vw;
            --width: 100vw;
            --min-height: 100vh;
            --height: 100vh;
            border-radius: 0;
        }
    }
}

/* ######################################################################################### %
                                                                HRSUI GLOBAL RULES
% ######################################################################################### */

.c_call--header-hrsui {
    --header-toolbar-offset: 2.5vh;
    --header-spacing-unit: 0.5rem;
}

/* ######################################################################################### %
                                      ICON PATHS FOR PATTERN LIBRARY
% ######################################################################################### */
.c_app_header--icon-back { content: url(/assets/icon/icon-arrow_back.svg); }
.c_app_header--icon-back.is-modal { content: url(/assets/icon/icon-arrow_back-black.svg); }
.c_footer--icon-back { content: url(/assets/icon/icon-arrow_back-white.svg); }
.c_chat--icon-send { content: url(/assets/icon/icon-send_email.svg); }
