@import '../theme/variables';

.toast-warning {
    .toast-container {
        background: var(--color-error);
        text-align: center;
    }
    .toast-message {
        font-size: 18px;
    }
}

.c_caregiver_alert > .alert-wrapper.alert-wrapper .alert-button {
    margin: 0.5rem;

    &.c_caregiver_alert--button-cancel {
        background: var(--gray-1);
        color: var(--black);
    }
}
.btn-vonage-video button{
    width: 70px; 
    height: 70px; 
    background: none;
}

