app-caregiver-pin {
    height: 100%;

    .text-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        hrs-text {
            &:first-of-type {
                margin-bottom: 1.25rem;
            }

            &:last-of-type {
                width: 17rem;
            }

            @media (min-width: 600px) {
                &:first-of-type {
                    margin-bottom: 1rem;
                }

                &:last-of-type {
                    width: 28.5rem;
                }
            }
        }
    }
}
